<template>
  <div
    class="echart"
    id="mychart"
    :style="{ width: '100%', height: '400px' }"
  ></div>
</template>

<script>
import * as echarts from 'echarts'
import 'echarts-gl'
import { andriodDataObj,iosDataObj } from '../world.js'
// import world from 'echarts/map/js/world.js';
export default {
  data() {
    return {
      heightTexture: '', //纵坐标数据，与横坐标对应
      dataObj:[],
    }
  },
  mounted() {
    this.appSource()
    this.initEcharts()
  },
  methods: {
    appSource() {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        this.dataObj = iosDataObj
       
      } else {
        this.dataObj = andriodDataObj
      }
    },
    initEcharts() {
      var chart = echarts.getInstanceByDom(document.getElementById('mychart'))
      if (!chart || chart.isDisposed()) {
        chart = echarts.init(document.getElementById('mychart'))
      }
      // let ds = [

      // ]

      // 点配置信息
      // let series = ds.map((item) => {
      //   return {
      //     //  type: 'lines3D',
      //     // coordinateSystem: 'globe',
      //     // blendMode: 'lighter',
      //     effect: {
      //       // 飞线 特效
      //       show: true,
      //       peroid: 2,
      //       trailColor: 'greenyellow',
      //     },
      //     lineStyle: {
      //       // 飞线颜色
      //       width: 2,
      //       color: '#02fd00',
      //       opacity: 0.8,
      //     },
      //     name: item.name, // 是否显示左上角图例
      //     type: 'scatter3D',
      //     coordinateSystem: 'globe',
      //     blendMode: 'lighter',
      //     symbolSize: 16, // 点位大小
      //     itemStyle: {
      //       color: item.itemStyleColor, // 各个点位的颜色设置
      //       // opacity: 1, // 透明度
      //       // borderWidth: 1, // 边框宽度
      //       // borderColor: 'rgba(255,255,255,0.8)' //rgba(180, 31, 107, 0.8)
      //     },
      //     label: {
      //       show: true, // 是否显示字体
      //       position: 'left', // 字体位置。top、left、right、bottom
      //       formatter: item.labelText, // 具体显示的值
      //       textStyle: {
      //         color: '#fc0303', // 字体颜色
      //         borderWidth: 0, // 字体边框宽度
      //         borderColor: '#fff', // 字体边框颜色
      //         fontFamily: 'sans-serif', // 字体格式
      //         fontSize: 12, // 字体大小
      //         fontWeight: 600,
      //       },
      //     },
      //     data: [item.point], // 数据来源
      //   }
      // })
      const dataObj = this.dataObj
      const option = {
        backgroundColor: '#000',
        globe: {
          baseTexture: require('../assets/earth.jpg'),
          // heightTexture: require('./assets/bathymetry_bw_composite_4k.jpg'),
          displacementScale: 0.1,
          shading: 'lambert',
          environment: require('../assets/starfield.jpg'),
          light: {
            ambient: {
              intensity: 0.1,
            },
            main: {
              intensity: 1.5,
            },
          },

          layers: [
            {
              type: 'blend',
              blendTo: 'emission',
              texture: require('../assets/night.jpg'),
            },
            {
              type: 'overlay',
              texture: require('../assets/clouds.png'),
              shading: 'lambert',
              distance: 5,
            },
          ],
        },
        // series: [
        //   {
        //     type: 'scatter3D',
        //     coordinateSystem: 'globe',
        //     blendMode: 'lighter',
        //     symbolSize: 16, // 点位大小
        //     itemStyle: {
        //       color: '#0000FF', // 各个点位的颜色设置
        //       // opacity: 1, // 透明度
        //       // borderWidth: 1, // 边框宽度
        //       // borderColor: 'rgba(255,255,255,0.8)' //rgba(180, 31, 107, 0.8)
        //     },
        //     label: {
        //       show: true, // 是否显示字体
        //       position: 'left', // 字体位置。top、left、right、bottom
        //       formatter: '中国', // 具体显示的值
        //       textStyle: {
        //         color: '#fc0303', // 字体颜色
        //         borderWidth: 0, // 字体边框宽度
        //         borderColor: '#fff', // 字体边框颜色
        //         fontFamily: 'sans-serif', // 字体格式
        //         fontSize: 12, // 字体大小
        //         fontWeight: 600,
        //       },
        //     },
        //     data: [[23.46, 37.58, 0]], // 数据来源
        //   },
        // ],
        series: (function () {
          let serie = []
          for (var i = 0; i <  dataObj.length; i++) {
            let data = {
              //  type: 'lines3D',
              // coordinateSystem: 'globe',
              // blendMode: 'lighter',
              effect: {
                // 飞线 特效
                show: true,
                peroid: 2,
                trailColor: 'greenyellow',
              },
              lineStyle: {
                // 飞线颜色
                width: 2,
                color: '#02fd00',
                opacity: 0.8,
              },
              type: 'scatter3D',
              coordinateSystem: 'globe',
              blendMode: 'lighter',
              symbolSize: 16, // 点位大小
              itemStyle: {
                color:  dataObj[i].itemStyleColor, // 各个点位的颜色设置
                // opacity: 1, // 透明度
                // borderWidth: 1, // 边框宽度
                // borderColor: 'rgba(255,255,255,0.8)' //rgba(180, 31, 107, 0.8)
              },
              label: {
                show: true, // 是否显示字体
                position: 'left', // 字体位置。top、left、right、bottom
                formatter:  dataObj[i].labelText, // 具体显示的值
                textStyle: {
                  color: '#fc0303', // 字体颜色
                  borderWidth: 0, // 字体边框宽度
                  borderColor: '#fff', // 字体边框颜色
                  fontFamily: 'sans-serif', // 字体格式
                  fontSize: 12, // 字体大小
                  fontWeight: 600,
                },
              },
              data: [ dataObj[i].point], // 数据来源
            }
            serie.push(data)
          }

          // dataObj.map((item) => {
          //   let data = {
          //               //  type: 'lines3D',
          // // coordinateSystem: 'globe',
          // // blendMode: 'lighter',
          // effect: {
          //   // 飞线 特效
          //   show: true,
          //   peroid: 2,
          //   trailColor: 'greenyellow',
          // },
          // lineStyle: {
          //   // 飞线颜色
          //   width: 2,
          //   color: '#02fd00',
          //   opacity: 0.8,
          // },
          // name: item.name, // 是否显示左上角图例
          // type: 'scatter3D',
          // coordinateSystem: 'globe',
          // blendMode: 'lighter',
          // symbolSize: 16, // 点位大小
          // itemStyle: {
          //   color: item.itemStyleColor, // 各个点位的颜色设置
          //   // opacity: 1, // 透明度
          //   // borderWidth: 1, // 边框宽度
          //   // borderColor: 'rgba(255,255,255,0.8)' //rgba(180, 31, 107, 0.8)
          // },
          // label: {
          //   show: true, // 是否显示字体
          //   position: 'left', // 字体位置。top、left、right、bottom
          //   formatter: item.labelText, // 具体显示的值
          //   textStyle: {
          //     color: '#fc0303', // 字体颜色
          //     borderWidth: 0, // 字体边框宽度
          //     borderColor: '#fff', // 字体边框颜色
          //     fontFamily: 'sans-serif', // 字体格式
          //     fontSize: 12, // 字体大小
          //     fontWeight: 600,
          //   },
          // },
          // data: [item.point], // 数据来源
          //   }
          //   serie.push(data);
          // });
          return serie
        })(),
      }
      option && chart.setOption(option)
      //随着屏幕大小调节图表
      // window.addEventListener('resize', () => {
      //   myChart.resize()
      // })
    },
  },
}
</script>
<style></style>
