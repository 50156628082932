const andriodDataObj = [
  {
    name: '中国',
    point: [114.11, 22.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '中国香港',
  },
  {
    name: '印度',
    point: [78.96288, 20.593684, 0],
    itemStyleColor: '#0000FF',
    labelText: '印度',
  },
  {
    name: '意大利',
    point: [12.56738, 41.87194, 0],
    itemStyleColor: '#0000FF',
    details: 'XXXXXXXXXXXXXXXXXXXXXX',
    labelText: '意大利',
  },
  {
    name: '美国',
    point: [-95.712891, 37.09024, 0],
    itemStyleColor: '#0000FF',
    labelText: '美国',
  },
  {
    name: '蒙古',
    point: [103.846656, 46.862496, 0],
    itemStyleColor: '#0000FF',
    labelText: '蒙古',
  },
  {
    name: '加拿大',
    point: [-106.346771, 56.130366, 0],
    itemStyleColor: '#0000FF',
    labelText: '加拿大',
  },

  {
    name: '日本',
    point: [138.252924, 36.204824, 0],
    itemStyleColor: '#0000FF',
    labelText: '日本',
  },

  {
    name: '越南',
    point: [105.55, 21.05, 0],
    itemStyleColor: '#0000FF',
    labelText: '越南',
  },
  {
    name: '菲律宾',
    point: [121.03, 14.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '菲律宾',
  },
  {
    name: '泰国',
    point: [100.35, 13.45, 0],
    itemStyleColor: '#0000FF',
    labelText: '泰国',
  },
  {
    name: '不丹',
    point: [89.45, 27.31, 0],
    itemStyleColor: '#0000FF',
    labelText: '不丹',
  },
  {
    name: '尼泊尔',
    point: [85.2, 27.45, 0],
    itemStyleColor: '#0000FF',
    labelText: '尼泊尔',
  },
  {
    name: '土耳其',
    point: [32.54, 39.57, 0],
    itemStyleColor: '#0000FF',
    labelText: '土耳其',
  },
  {
    name: '柬埔寨',
    point: [104.55, 11.33, 0],
    itemStyleColor: '#0000FF',
    labelText: '柬埔寨',
  },
  {
    name: '伊拉克',
    point: [44.3, 33.2, 0],
    itemStyleColor: '#0000FF',
    labelText: '伊拉克',
  },
  {
    name: '科威特',
    point: [48.0, 29.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '科威特',
  },
  {
    name: '缅甸',
    point: [96.2, 16.45, 0],
    itemStyleColor: '#0000FF',
    labelText: '缅甸',
  },
  {
    name: '斯里兰卡',
    point: [80.705613, 7.6657, 0],
    itemStyleColor: '#0000FF',
    labelText: '斯里兰卡',
  },
  {
    name: '孟加拉',
    point: [90.254063, 24.204019, 0],
    itemStyleColor: '#0000FF',
    labelText: '孟加拉',
  },
  {
    name: '乌兹别克斯坦',
    point: [69.1, 41.2, 0],
    itemStyleColor: '#0000FF',
    labelText: '乌兹别克斯坦',
  },
  {
    name: '印度尼西亚',
    point: [106.49, -6.09, 0],
    itemStyleColor: '#0000FF',
    labelText: '印度尼西亚',
  },
  {
    name: '亚美尼亚',
    point: [44.31, 40.1, 0],
    itemStyleColor: '#0000FF',
    labelText: '亚美尼亚',
  },
  {
    name: '文莱',
    point: [115.0, 4.52, 0],
    itemStyleColor: '#0000FF',
    labelText: '文莱',
  },
  {
    name: '阿曼',
    point: [58.36, 23.37, 0],
    itemStyleColor: '#0000FF',
    labelText: '阿曼',
  },
  {
    name: '新加坡',
    point: [103.864154, 1.362735, 0],
    itemStyleColor: '#0000FF',
    labelText: '新加坡',
  },
  {
    name: '马来西亚',
    point: [101.41, 3.09, 0],
    itemStyleColor: '#0000FF',
    labelText: '马来西亚',
  },
  {
    name: '老挝',
    point: [102.36, 17.58, 0],
    itemStyleColor: '#0000FF',
    labelText: '老挝',
  },
  {
    name: '委内瑞拉',
    point: [-66.55, 10.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '委内瑞拉',
  },
  {
    name: '巴拉圭',
    point: [28.12, -25.44, 0],
    itemStyleColor: '#0000FF',
    labelText: '巴拉圭',
  },
  {
    name: '阿根廷',
    point: [-60.0, -36.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '阿根廷',
  },
  {
    name: '智利',
    point: [-70.4, -33.24, 0],
    itemStyleColor: '#0000FF',
    labelText: '智利',
  },
  {
    name: '厄瓜多尔',
    point: [-78.35, -0.15, 0],
    itemStyleColor: '#0000FF',
    labelText: '厄瓜多尔',
  },
  {
    name: '玻利维亚',
    point: [-68.1, -16.2, 0],
    itemStyleColor: '#0000FF',
    labelText: '玻利维亚',
  },
  {
    name: '秘鲁',
    point: [-77.0, -12.0, 0],
    itemStyleColor: '#0000FF',
    labelText: '秘鲁',
  },
  {
    name: '巴西',
    point: [-47.55, -15.47, 0],
    itemStyleColor: '#0000FF',
    labelText: '巴西',
  },
  {
    name: '哥伦比亚',
    point: [-74.0, 4.34, 0],
    itemStyleColor: '#0000FF',
    labelText: '哥伦比亚',
  },
  {
    name: '乌拉圭',
    point: [-56.11, -34.5, 0],
    itemStyleColor: '#0000FF',
    labelText: '乌拉圭',
  },
  {
    name: '墨西哥',
    point: [-99.1, 19.2, 0],
    itemStyleColor: '#0000FF',
    labelText: '墨西哥',
  },
  {
    name: '巴拿马',
    point: [-79.25, 9.0, 0],
    itemStyleColor: '#0000FF',
    labelText: '巴拿马',
  },
  {
    name: '古巴',
    point: [-82.22, 23.08, 0],
    itemStyleColor: '#0000FF',
    labelText: '古巴',
  },
  {
    name: '尼加拉瓜',
    point: [-86.2, 12.06, 0],
    itemStyleColor: '#0000FF',
    labelText: '尼加拉瓜',
  },
  {
    name: '巴哈马',
    point: [-77.2, 25.05, 0],
    itemStyleColor: '#0000FF',
    labelText: '巴哈马',
  },
  {
    name: '多米尼克',
    point: [1111, 2222, 0],
    itemStyleColor: '#0000FF',
    labelText: '多米尼克',
  },
  {
    name: '牙买加',
    point: [-76.5, 18.0, 0],
    itemStyleColor: '#0000FF',
    labelText: '牙买加',
  },
  {
    name: '危地马拉',
    point: [-90.22, 14.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '危地马拉',
  },
  {
    name: '萨尔瓦多',
    point: [-89.1, 13.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '萨尔瓦多',
  },
  {
    name: '洪都拉斯',
    point: [-87.14, 14.05, 0],
    itemStyleColor: '#0000FF',
    labelText: '洪都拉斯',
  },
  {
    name: '海地',
    point: [-72.2, 18.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '海地',
  },
  {
    name: '索马里',
    point: [45.25, 2.02, 0],
    itemStyleColor: '#0000FF',
    labelText: '索马里',
  },
  {
    name: '苏丹',
    point: [32.35, 15.31, 0],
    itemStyleColor: '#0000FF',
    labelText: '苏丹',
  },
  {
    name: '中非共和国',
    point: [18.35, 4.23, 0],
    itemStyleColor: '#0000FF',
    labelText: '中非共和国',
  },
  {
    name: '布隆迪',
    point: [29.18, -3.16, 0],
    itemStyleColor: '#0000FF',
    labelText: '布隆迪',
  },
  {
    name: '埃及',
    point: [31.14, 30.01, 0],
    itemStyleColor: '#0000FF',
    labelText: '埃及',
  },
  {
    name: '肯尼亚',
    point: [36.48, -1.17, 0],
    itemStyleColor: '#0000FF',
    labelText: '肯尼亚',
  },
  {
    name: '摩洛哥',
    point: [-6.240875, 31.967759, 0],
    itemStyleColor: '#0000FF',
    labelText: '摩洛哥',
  },
  {
    name: '卢旺达',
    point: [30.04, -1.59, 0],
    itemStyleColor: '#0000FF',
    labelText: '卢旺达',
  },
  {
    name: '喀麦隆',
    point: [11.35, 3.5, 0],
    itemStyleColor: '#0000FF',
    labelText: '喀麦隆',
  },
  {
    name: '埃塞俄比亚',
    point: [38.42, 9.02, 0],
    itemStyleColor: '#0000FF',
    labelText: '埃塞俄比亚',
  },
  {
    name: '利比亚',
    point: [17.583546, 27.055803, 0],
    itemStyleColor: '#0000FF',
    labelText: '利比亚',
  },
  {
    name: '利比里亚',
    point: [-10.47, 6.18, 0],
    itemStyleColor: '#0000FF',
    labelText: '利比里亚',
  },
  {
    name: '纳米比亚',
    point: [17.04, -22.35, 0],
    itemStyleColor: '#0000FF',
    labelText: '纳米比亚',
  },
  {
    name: '科摩罗',
    point: [43.16, -11.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '科摩罗',
  },
  {
    name: '吉布提',
    point: [42.2, 11.08, 0],
    itemStyleColor: '#0000FF',
    labelText: '吉布提',
  },
  {
    name: '多哥',
    point: [1.2, 6.09, 0],
    itemStyleColor: '#0000FF',
    labelText: '多哥',
  },
  {
    name: '津巴布韦',
    point: [31.02, -17.43, 0],
    itemStyleColor: '#0000FF',
    labelText: '津巴布韦',
  },
  {
    name: '阿尔及利亚',
    point: [3.08, 36.42, 0],
    itemStyleColor: '#0000FF',
    labelText: '阿尔及利亚',
  },
  {
    name: '塞拉利昂',
    point: [-13.17, 8.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '塞拉利昂',
  },
  {
    name: '新西兰',
    point: [174.46, -41.19, 0],
    itemStyleColor: '#0000FF',
    labelText: '新西兰',
  },
  {
    name: '澳大利亚',
    point: [149.08, -35.15, 0],
    itemStyleColor: '#0000FF',
    labelText: '澳大利亚',
  },
  {
    name: '英国',
    point: [-0.05, 51.36, 0],
    itemStyleColor: '#0000FF',
    labelText: '英国',
  },
  {
    name: '法国',
    point: [2.2, 48.5, 0],
    itemStyleColor: '#0000FF',
    labelText: '法国',
  },
  {
    name: '瑞典',
    point: [18.03, 59.2, 0],
    itemStyleColor: '#0000FF',
    labelText: '瑞典',
  },
  {
    name: '芬兰',
    point: [25.03, 60.15, 0],
    itemStyleColor: '#0000FF',
    labelText: '芬兰',
  },
  {
    name: '意大利',
    point: [12.29, 41.54, 0],
    itemStyleColor: '#0000FF',
    labelText: '意大利',
  },
  {
    name: '俄罗斯',
    point: [37.35, 55.45, 0],
    itemStyleColor: '#0000FF',
    labelText: '俄罗斯',
  },
  {
    name: '马其顿',
    point: [21.26, 42.01, 0],
    itemStyleColor: '#0000FF',
    labelText: '马其顿',
  },
  {
    name: '西班牙',
    point: [-3.45, 40.25, 0],
    itemStyleColor: '#0000FF',
    labelText: '西班牙',
  },
  {
    name: '捷克',
    point: [14.22, 50.05, 0],
    itemStyleColor: '#0000FF',
    labelText: '捷克',
  },
  {
    name: '以色列',
    point: [35.12, 31.47, 0],
    itemStyleColor: '#0000FF',
    labelText: '以色列',
  },
  {
    name: '奥地利',
    point: [16.22, 48.12, 0],
    itemStyleColor: '#0000FF',
    labelText: '奥地利',
  },
  {
    name: '塞尔维亚',
    point: [19.251107, 42.890781, 0],
    itemStyleColor: '#0000FF',
    labelText: '塞尔维亚',
  },
  {
    name: '瑞士',
    point: [7.28, 46.57, 0],
    itemStyleColor: '#0000FF',
    labelText: '瑞士',
  },
  {
    name: '希腊',
    point: [23.46, 37.58, 0],
    itemStyleColor: '#0000FF',
    labelText: '希腊',
  },
  {
    name: '德国',
    point: [13.25, 52.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '德国',
  },
  {
    name: '乌克兰',
    point: [30.28, 50.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '乌克兰',
  },
  {
    name: '葡萄牙',
    point: [-9.1, 38.42, 0],
    itemStyleColor: '#0000FF',
    labelText: '葡萄牙',
  },
  {
    name: '丹麦',
    point: [12.34, 55.41, 0],
    itemStyleColor: '#0000FF',
    labelText: '丹麦',
  },
  {
    name: '保加利亚',
    point: [23.2, 42.45, 0],
    itemStyleColor: '#0000FF',
    labelText: '保加利亚',
  },
]
const iosDataObj = [
  {
    name: '中国',
    point: [114.11, 22.4, 0],
    itemStyleColor: '#0000FF',
    labelText: '中国香港',
  },
  {
    name: '印度',
    point: [78.96288, 20.593684, 0],
    itemStyleColor: '#0000FF',
    labelText: '印度',
  },
  {
    name: '意大利',
    point: [12.56738, 41.87194, 0],
    itemStyleColor: '#0000FF',
    details: 'XXXXXXXXXXXXXXXXXXXXXX',
    labelText: '意大利',
  },
  {
    name: '美国',
    point: [-95.712891, 37.09024, 0],
    itemStyleColor: '#0000FF',
    labelText: '美国',
  },
  {
    name: '蒙古',
    point: [103.846656, 46.862496, 0],
    itemStyleColor: '#0000FF',
    labelText: '蒙古',
  },
  {
    name: '加拿大',
    point: [-106.346771, 56.130366, 0],
    itemStyleColor: '#0000FF',
    labelText: '加拿大',
  },

  {
    name: '日本',
    point: [138.252924, 36.204824, 0],
    itemStyleColor: '#0000FF',
    labelText: '日本',
  },

  {
    name: '越南',
    point: [105.55, 21.05, 0],
    itemStyleColor: '#0000FF',
    labelText: '越南',
  },
  // {
  //   name: '菲律宾',
  //   point: [121.03, 14.4, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '菲律宾',
  // },
  // {
  //   name: '泰国',
  //   point: [100.35, 13.45, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '泰国',
  // },
  // {
  //   name: '不丹',
  //   point: [89.45, 27.31, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '不丹',
  // },
  // {
  //   name: '尼泊尔',
  //   point: [85.2, 27.45, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '尼泊尔',
  // },
  // {
  //   name: '土耳其',
  //   point: [32.54, 39.57, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '土耳其',
  // },
  // {
  //   name: '柬埔寨',
  //   point: [104.55, 11.33, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '柬埔寨',
  // },
  // {
  //   name: '伊拉克',
  //   point: [44.3, 33.2, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '伊拉克',
  // },
  // {
  //   name: '科威特',
  //   point: [48.0, 29.3, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '科威特',
  // },
  // {
  //   name: '缅甸',
  //   point: [96.2, 16.45, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '缅甸',
  // },
  // {
  //   name: '斯里兰卡',
  //   point: [80.705613, 7.6657, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '斯里兰卡',
  // },
  // {
  //   name: '孟加拉',
  //   point: [90.254063, 24.204019, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '孟加拉',
  // },
  // {
  //   name: '乌兹别克斯坦',
  //   point: [69.1, 41.2, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '乌兹别克斯坦',
  // },
  // {
  //   name: '印度尼西亚',
  //   point: [106.49, -6.09, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '印度尼西亚',
  // },
  // {
  //   name: '亚美尼亚',
  //   point: [44.31, 40.1, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '亚美尼亚',
  // },
  // {
  //   name: '文莱',
  //   point: [115.0, 4.52, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '文莱',
  // },
  {
    name: '阿曼',
    point: [58.36, 23.37, 0],
    itemStyleColor: '#0000FF',
    labelText: '阿曼',
  },
  {
    name: '新加坡',
    point: [103.864154, 1.362735, 0],
    itemStyleColor: '#0000FF',
    labelText: '新加坡',
  },
  {
    name: '马来西亚',
    point: [101.41, 3.09, 0],
    itemStyleColor: '#0000FF',
    labelText: '马来西亚',
  },
  {
    name: '老挝',
    point: [102.36, 17.58, 0],
    itemStyleColor: '#0000FF',
    labelText: '老挝',
  },
  {
    name: '委内瑞拉',
    point: [-66.55, 10.3, 0],
    itemStyleColor: '#0000FF',
    labelText: '委内瑞拉',
  },
  // {
  //   name: '巴拉圭',
  //   point: [28.12, -25.44, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '巴拉圭',
  // },
  // {
  //   name: '阿根廷',
  //   point: [-60.0, -36.3, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '阿根廷',
  // },
  // {
  //   name: '智利',
  //   point: [-70.4, -33.24, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '智利',
  // },
  // {
  //   name: '厄瓜多尔',
  //   point: [-78.35, -0.15, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '厄瓜多尔',
  // },
  // {
  //   name: '玻利维亚',
  //   point: [-68.1, -16.2, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '玻利维亚',
  // },
  // {
  //   name: '秘鲁',
  //   point: [-77.0, -12.0, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '秘鲁',
  // },
  // {
  //   name: '巴西',
  //   point: [-47.55, -15.47, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '巴西',
  // },
  // {
  //   name: '哥伦比亚',
  //   point: [-74.0, 4.34, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '哥伦比亚',
  // },
  // {
  //   name: '乌拉圭',
  //   point: [-56.11, -34.5, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '乌拉圭',
  // },
  // {
  //   name: '墨西哥',
  //   point: [-99.1, 19.2, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '墨西哥',
  // },
  // {
  //   name: '巴拿马',
  //   point: [-79.25, 9.0, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '巴拿马',
  // },
  // {
  //   name: '古巴',
  //   point: [-82.22, 23.08, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '古巴',
  // },
  // {
  //   name: '尼加拉瓜',
  //   point: [-86.2, 12.06, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '尼加拉瓜',
  // },
  // {
  //   name: '巴哈马',
  //   point: [-77.2, 25.05, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '巴哈马',
  // },
  // {
  //   name: '多米尼克',
  //   point: [1111, 2222, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '多米尼克',
  // },
  // {
  //   name: '牙买加',
  //   point: [-76.5, 18.0, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '牙买加',
  // },
  // {
  //   name: '危地马拉',
  //   point: [-90.22, 14.4, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '危地马拉',
  // },
  // {
  //   name: '萨尔瓦多',
  //   point: [-89.1, 13.4, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '萨尔瓦多',
  // },
  // {
  //   name: '洪都拉斯',
  //   point: [-87.14, 14.05, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '洪都拉斯',
  // },
  // {
  //   name: '海地',
  //   point: [-72.2, 18.4, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '海地',
  // },
  // {
  //   name: '索马里',
  //   point: [45.25, 2.02, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '索马里',
  // },
  // {
  //   name: '苏丹',
  //   point: [32.35, 15.31, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '苏丹',
  // },
  // {
  //   name: '中非共和国',
  //   point: [18.35, 4.23, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '中非共和国',
  // },
  // {
  //   name: '布隆迪',
  //   point: [29.18, -3.16, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '布隆迪',
  // },
  // {
  //   name: '埃及',
  //   point: [31.14, 30.01, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '埃及',
  // },
  // {
  //   name: '肯尼亚',
  //   point: [36.48, -1.17, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '肯尼亚',
  // },
  // {
  //   name: '摩洛哥',
  //   point: [-6.240875, 31.967759, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '摩洛哥',
  // },
  // {
  //   name: '卢旺达',
  //   point: [30.04, -1.59, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '卢旺达',
  // },
  // {
  //   name: '喀麦隆',
  //   point: [11.35, 3.5, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '喀麦隆',
  // },
  // {
  //   name: '埃塞俄比亚',
  //   point: [38.42, 9.02, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '埃塞俄比亚',
  // },
  // {
  //   name: '利比亚',
  //   point: [17.583546, 27.055803, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '利比亚',
  // },
  // {
  //   name: '利比里亚',
  //   point: [-10.47, 6.18, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '利比里亚',
  // },
  // {
  //   name: '纳米比亚',
  //   point: [17.04, -22.35, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '纳米比亚',
  // },
  // {
  //   name: '科摩罗',
  //   point: [43.16, -11.4, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '科摩罗',
  // },
  // {
  //   name: '吉布提',
  //   point: [42.2, 11.08, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '吉布提',
  // },
  // {
  //   name: '多哥',
  //   point: [1.2, 6.09, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '多哥',
  // },
  // {
  //   name: '津巴布韦',
  //   point: [31.02, -17.43, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '津巴布韦',
  // },
  // {
  //   name: '阿尔及利亚',
  //   point: [3.08, 36.42, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '阿尔及利亚',
  // },
  // {
  //   name: '塞拉利昂',
  //   point: [-13.17, 8.3, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '塞拉利昂',
  // },
  // {
  //   name: '新西兰',
  //   point: [174.46, -41.19, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '新西兰',
  // },
  // {
  //   name: '澳大利亚',
  //   point: [149.08, -35.15, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '澳大利亚',
  // },
  // {
  //   name: '英国',
  //   point: [-0.05, 51.36, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '英国',
  // },
  // {
  //   name: '法国',
  //   point: [2.2, 48.5, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '法国',
  // },
  // {
  //   name: '瑞典',
  //   point: [18.03, 59.2, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '瑞典',
  // },
  // {
  //   name: '芬兰',
  //   point: [25.03, 60.15, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '芬兰',
  // },
  // {
  //   name: '意大利',
  //   point: [12.29, 41.54, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '意大利',
  // },
  // {
  //   name: '俄罗斯',
  //   point: [37.35, 55.45, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '俄罗斯',
  // },
  // {
  //   name: '马其顿',
  //   point: [21.26, 42.01, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '马其顿',
  // },
  // {
  //   name: '西班牙',
  //   point: [-3.45, 40.25, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '西班牙',
  // },
  // {
  //   name: '捷克',
  //   point: [14.22, 50.05, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '捷克',
  // },
  // {
  //   name: '以色列',
  //   point: [35.12, 31.47, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '以色列',
  // },
  // {
  //   name: '奥地利',
  //   point: [16.22, 48.12, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '奥地利',
  // },
  // {
  //   name: '塞尔维亚',
  //   point: [19.251107, 42.890781, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '塞尔维亚',
  // },
  // {
  //   name: '瑞士',
  //   point: [7.28, 46.57, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '瑞士',
  // },
  // {
  //   name: '希腊',
  //   point: [23.46, 37.58, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '希腊',
  // },
  // {
  //   name: '德国',
  //   point: [13.25, 52.3, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '德国',
  // },
  // {
  //   name: '乌克兰',
  //   point: [30.28, 50.3, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '乌克兰',
  // },
  // {
  //   name: '葡萄牙',
  //   point: [-9.1, 38.42, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '葡萄牙',
  // },
  // {
  //   name: '丹麦',
  //   point: [12.34, 55.41, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '丹麦',
  // },
  // {
  //   name: '保加利亚',
  //   point: [23.2, 42.45, 0],
  //   itemStyleColor: '#0000FF',
  //   labelText: '保加利亚',
  // },
]
export {
  andriodDataObj,
  iosDataObj
}