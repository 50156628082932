<template>
  <!-- <div>
    <img src="./assets/logo.png" alt="" class="logoImg">
  </div> -->
  <div class="globlWarper">
  
  </div>
  <TopEarth/>
  <!-- <div
    class="echart"
    id="mychart"
    :style="{  width: '100%', height: '400px' }"
  ></div> -->
  
  <div style="padding: 20px;font-weight:700;">
    <!-- <p>请选择洲际/国家查看详情</p>
      
    <a-cascader
      :options="options"
      placeholder="选择洲际/国家"
    /> -->
      <div style="border-top:2px solid black;margin: 20px 0;"></div>
      <h1 style="font-weight:800;margin:10px 0">川海行联会</h1>
     <div style="font-size: 20px;margin: 20px 0;">联会简介:川海行联会为1994年在吉隆坡注册的一个非政府组织，目标为全球华人在经济、政治、生活上互助互联，提升全球华人的国际地位。由马籍华人Au ming成立。 迄今拥有会员64万余人，遍布80多个国家和地区，是东南亚地区最大的华人华侨民间组织 </div> 
  </div>
  <div class="globlWarper borderWarper">
  </div>
  <div id="globalArea" style="width: 100%; height: 400px"></div>
  <div style="border-top:2px solid black;margin: 20px 0;"></div>
  <div v-if="showDetail" style="padding:20px;font-size: 20px;margin: 20px 0;font-weight:700;">{{ detailLst.detail }}</div>
</template>

<script>
import TopEarth from './components/topEarth.vue'
// import * as echarts from 'echarts'
// import 'echarts-gl'
// import world from 'echarts/map/js/world.js';


export default {
  data() {
    return {
      heightTexture: '', //纵坐标数据，与横坐标对应
      showDetail: true,
      detailLst: {
        detail:
          '川海行联会（总会）：为1994年在吉隆坡注册的一个非政府组织，目标为全球华人在经济、政治、生活上互助互联，提升全球华人的国际地位。由马籍华人Au ming成立。 迄今拥有会员64万余人，遍布80多个国家和地区，是东南亚地区最 大的华人华侨民间组织。川海行联会最初是由早期移民马来西亚华侨为保障自身合法权益而形成的利益互帮团体，初期旨在团结并使用所有在马来西亚华侨、华商的共同力量，抵抗马来西亚本土黑恶势力对移民华侨、华商的恶意骚扰。',
      },
      selectedCountryData: [
        {
          name: 'ZIMBABWE',
          chineseName: '津巴布韦',
          detail:
            '川海行联会（津巴布韦）：成立于2016年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员210余名，个人会员4700余名；累计援助失学适龄儿童1800余名，援建健康饮水点59处；（2017年3月，由于连遭暴雨袭击，津巴布韦多数地区出现洪涝灾害，直接导致246人死亡、另有2000多人流离失所。津巴布韦分会知悉消息后，立即组织2000余/人次分会义工前往灾区，并配合国际联会向灾区捐赠3600顶帐篷，生活医疗物资5吨。同年津巴布韦分会被当地商会组织评选为“Fist gat”组织）',
        },
        {
          name: 'CHINA',
          chineseName: '香港',
          detail:
            '川海行联会（香港）：成立于2018年6月，截止到2022年12月底。该分会上交国际联会登记在录商业会员300余名，个人会员20000余名。（2020年香港分会开始在国内发展义工团队在中国内地开展工作，于陕西省推行首个项目。2020年6月国际联会下属川海行基金设立中国发展基金。自此，我们一直致力在内地开展扶贫工作，从推动农村发展与灾害管理，开展紧急救援工作，以至推行城市生计发展及倡议项目。在2021年川海行基金正式成立香港项目部，投入中国内地的扶贫项目资金也正在与日剧增，项目部也在逐渐将原有在中国内地传统单一的扶贫义工项目改为扶持当地多元化经济种植、养殖；解决当地人口健康饮水；辅助当地商业会员发展的良性发展方式。过去多年，香港分会在中国内地先后与内地商业会员一起根据当地自然条件开创了12个扶贫种植、养殖示范基地，采用“以养代工，以种代工”等方式，带动了14000多名贫困人口脱贫致富。川海行基金的扶贫基金是支持我们的主要收入来源。',
        },
        {
          name: 'RUSSIAN FEDERATION',
          chineseName: '俄罗斯',
          detail:
            '川海行联会（俄罗斯）：成立于2012年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员246余名，个人会员12000余名；累计援助失学适龄儿童2800名，援建62处健康饮水点；（2018年2月12日，俄罗斯一家客机坠毁至71人遇难，俄罗斯分会对此次遇难家属遗孤提供40万卢比的慰问款项。）',
        },
        {
          name: 'CANADA',
          chineseName: '加拿大',
          detail:
            '川海行联会（加拿大）：成立于2014年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员400余名 ，个人会员20500余名。（2021年10月，在加拿大伊卡卢伊特发生了自来水水质污染事故，该事故造成当地居民大面积腹泻且对家中用水抱有恐惧心理。加拿大分会第一时间对周边国家发出协助申请，紧急调往事发地6000余份水质测试纸，免费赠送。以此来缓解当地居民的恐惧心理。）',
        },
        {
          name: 'UNITED STATES',
          chineseName: '美国',
          detail:
            '川海行联会（美国）：成立于2011年8月，截止到2022年12月底。该分会上交国际联会登记在录商业会员600余名 ，个人会员28000余名.美国分会成立以来先后共帮助10余名美裔华侨同胞回国找到自己的祖籍地。（2012年美国分会与当地华裔商会组成商业联盟 ， 开展国际港口贸易，给该地区港口贸易带来了年增长率6.7%，通过此次合作与当地华裔商会建立了良好的关系。）',
        },
        {
          name: 'KOREA, REPUBLIC OF',
          chineseName: '韩国',
          detail:
            '川海行联会（韩国）：成立于2006年5月，截止2022年12月底，该分会上交国际联会登记在录商业会员300余名，个人会员7000余名。（韩国分会始终坚持助力韩国安养，水原等地区的农业和渔业的发展并在当地设立专门的置业中心，为当地的农渔业经济发展提供多元化支持。2010年2月，国际联会受邀前往首尔进行半导体技术交流学习。）',
        },
        {
          name: 'JAPAN',
          chineseName: '日本',
          detail:
            '川海行联会（日本）：成立于2009年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员180余名，个人会员10300余名；(2012年4月，国际联会受日本红十字会的邀请前往日本交流社会援助、人群老龄化等多项议题，通过此次交流与日本多家社会援助单位建立了良好的交流合作关系。）',
        },
        {
          name: 'MONGOLIA',
          chineseName: '蒙古',
          detail:
            '川海行联会（蒙古）：成立于2007年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员300余名，个人会员9700余名。（自蒙古分会成立以来，协助蒙古弱势群体在蒙古多个地区设立农业生产单位置办处，帮助该地区参与人群年收入整体提升4.13%；受限于蒙古国基础设施落后，当地群众与分会共同创立的农业生产单位置办处的深加工产品难以外销，在国际联会与蒙古分会的共同努力下于2010年与蒙古国多家华人商会达成商业合作，并进行资源整合，解决了当地农业生产单位置办处深加工产品的外销问题）。',
        },
        {
          name: 'THAILAND',
          chineseName: '泰国',
          detail:
            '川海行联会（泰国）：成立于2003年11月，截止2022年12月底。该分会上交国际联会登记在录商业会员400余名，个人会员22000余名，泰国分会成立以来累积援助失学适龄儿童3800余名；2004年4月，国际联会受泰国农业合作社邀请，前往泰国进行“嫁接经济作物课题”的研讨。并在武里南等城市开展农业示范基地，促进了双方对于此课题的进一步合作。（2004年12月26日，泰国普吉岛发生严重海啸，泰国分会第一时间派出专业救援义工团队前往事发地点，协助当地救援人员展开搜救行动。此次援助活动中，泰国分会救援义工团队为超200名受灾灾民提供免费医疗援助。）',
        },
        {
          name: 'MYANMAR',
          chineseName: '缅甸',
          detail:
            '川海行联会（缅甸）：成立于2007年11月，至2022年12月底。该分会上交国际联会登记在录商业会员2200余名，个人会员25000余名，援助失学适龄儿童2600名。援建健康饮水点24处；（2008年5月2日，缅甸仰光受到强热带风暴影响，直接导致当地数万人死亡，约240万人受影响，缅甸分会启动紧急救灾预案，联合国际联会从各国分会调拨近7.6吨灾区急需物资前往灾区实施人道援助。）',
        },
        {
          name: 'INDIA',
          chineseName: '印度',
          detail:
            '川海行联会（印度）：成立于2004年5月，截止2022年12月底。该分会上交国际联会登记在录商业会员200余名，个人会员8700余名，援助失学适龄儿童3600余名，（2005年6月，该分会协助金奈金奈地区小镇设立农业生产单位置办处，给该地区的参与人群整体创收提升5%；2006年当地时间7月11日傍晚，印度西部城市孟买发生7起连环爆炸事件，造成了至少200人死亡。印度分会连夜调取大批救援物资转至灾区，协助当地受灾人群完成救灾工作。）',
        },
        {
          name: 'MALAYSIA',
          chineseName: '马来西亚',
          detail:
            '川海行联会（总会）：为1994年在吉隆坡注册的一个非政府组织，目标为全球华人在经济、政治、生活上互助互联，提升全球华人的国际地位。由马籍华人Au ming成立。 迄今拥有会员64万余人，遍布80多个国家和地区，是东南亚地区最 大的华人华侨民间组织。川海行联会最初是由早期移民马来西亚华侨为保障自身合法权益而形成的利益互帮团体，初期旨在团结并使用所有在马来西亚华侨、华商的共同力量，抵抗马来西亚本土黑恶势力对移民华侨、华商的恶意骚扰。',
        },
        {
          name: 'AUSTRALIA',
          chineseName: '澳大利亚',
          detail:
            '川海行联会（澳大利亚）：成立于2015年6月，截止2022年12月底，该分会上交国际联会登记再录商业会员201余名 ，个人会员5700余名.（2016年澳大利亚分会与新南威尔士州当地矿产企业交流沟通达成一致，共同开发铅、锌、银矿。）',
        },
        {
          name: 'NEW ZEALAND',
          chineseName: '新西兰',
          detail:
            '川海行联会(新西兰）：成立于2010年4月，截止2022年12月底，该分会上交国际联会登记在录商业会员218余名，个人会员6800余名（2015年4月国际联会受当地社会福利部门邀请前往新西兰交流多族裔人群的社会性公众养老议题，通过此次交流，新西兰分会正式与当地政府建立沟通桥梁，为当地华人的政治需求开始发声。）',
        },
        {
          name: 'CAMEROON',
          chineseName: '喀麦隆',
          detail:
            '川海行联会（喀麦隆）：成立于2015年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员136余名，个人会员5000余名，援助1900名失学适龄儿童，援建41处健康饮水点；(2021年的11月，位于喀麦隆西南地区的布埃亚大学发生爆炸，造成约200人受伤80人死亡，喀麦隆分会当即协调当地华人诊所多位医务人员以及急需医疗设备赶往事发地点展开救援。）',
        },
        {
          name: 'CENTRAL AFRICAN REPUBLIC',
          chineseName: '中非共和国',
          detail:
            '川海行联会（中非共和国）：成立于2013年9月，截止2021年12月底。该分会上交国际联会登记在录商业会员345余名，个人会员8698余名。援助适龄失学儿童3100余名。援建健康饮水点46处。(2020年12月10日，中非疫情爆发，新冠感染疫情病例大量增加，由于该国整体医疗资源短缺，造成大量病人无法得到及时救治。中非分会配合国际联会向当地政府及医疗单位捐赠呼吸机30台及大量口罩，消毒液以此援助当地的疫情救治。）',
        },
        {
          name: 'SUDAN',
          chineseName: '苏丹',
          detail:
            '川海行联会（苏丹）：成立于2017年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员200余名，个人会员2600余名，援助失学适龄儿童1800名。援建健康饮水点28处。(2022年8月23日，苏丹首都喀土穆，发生洪灾，导致33人死亡，30人受伤，数万栋房屋遭到损毁，苏丹分会第一时间组织分会义工前往灾区参与救援工作。国际联会当即捐助医疗和生活物资各五吨，以支援当地灾民的正常生活。）',
        },
        {
          name: 'ETHIOPIA',
          chineseName: '埃塞俄比亚',
          detail:
            '川海行联会（埃塞俄比亚）：成立于2013年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员400余名，个人会员7000余名。援助适龄失学儿童2800余名。援建健康饮水点28处。）',
        },
        {
          name: 'SOMALIA',
          chineseName: '索马里',
          detail:
            '川海行联会（索马里）：成立于2017年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员289余名，个人会员1800余名，援助失学适龄儿童2500名，援建健康饮水点31处；(2022年4月1日，索马里西北部城市哈尔格萨最大市场发生大火，近2600家商户被烧毁，索马里分会为受灾商户提供捐赠2000万法郎的灾后重建资金。）',
        },
        {
          name: 'BURUNDI',
          chineseName: '布隆迪',
          detail:
            '川海行联会（布隆迪 ）：成立于2015年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员105余名，个人会员1600余名，援助失学适龄儿童1700名，援建健康饮水点35处。(2016年4月11日，布隆迪首都布琼布拉以东18公里发生重大交通事故导致18人死亡，55人受伤，布隆迪分会会长李金文先生刚好经过事发地并亲自参与事故受伤人员的转运医院工作。）',
        },
        {
          name: 'LIBYA',
          chineseName: '利比亚',
          detail:
            '川海行联会（利比亚）：成立于2007年9月，截止2022年12月底。该分会上交国际联会登记在录商业会员200余名，个人会员5000余名.援助失学适龄儿童800余名，援建健康饮水点139处。（2011年2月利比亚骚乱进一步升级，利比亚分会为保障当地华人，华侨的个人人身安全，当即派出人员转运车辆75辆/车次，将当地华侨1500余人转移至安全地点）',
        },
        {
          name: 'ALGERIA',
          chineseName: '阿尔及利亚',
          detail:
            '川海行联会（阿尔及利亚）：成立于2002年9月，截止2022年12月底。该分会上交国际联会登记在录商业会员510余名，个人会员3860余名；累计援助失学适龄儿童750余名，援建健康饮水点49处；（2003年5月21日阿尔及尔发生6.7级地震，本次地震中死亡人数2273人，其中华人9人遇难，另有1万多人受伤，受灾人数超过10万余人。阿尔及利亚分会对在此次灾害中遇难的华人同胞家属提供免费往返机票以此来帮助受难同胞得到妥善安置。另对灾区捐赠大批物资如：帐篷、 毛毯、 矿泉水、压缩饼干等共计9吨救灾物资，用以援助灾民们的基本生活。）',
        },

        {
          name: 'MOROCCO',
          chineseName: '摩洛哥',
          detail:
            '川海行联会（摩洛哥）：成立于2012年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员80余名，个人会员6000余名；（2020年摩洛哥当地卫生部公布数据显示该国在过去24小时新增2234例新冠肺炎确诊病例，这是摩洛哥至新冠肺炎爆发以来新增病例最多的一天，摩洛哥分会为当地华人提供免费检测及送药上门服务。因此服务受益华人达1000多人。）',
        },
        {
          name: 'EGYPT',
          chineseName: '埃及',
          detail:
            '川海行联会（埃及）：成立于2011年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员180余名，个人会员9000余名。援建健康饮水点46处，援助失学适龄儿童2100名；（2021年4月18号，埃及盖卢比尤省发生一起严重的火车脱轨事件，造成32人死亡，近百人受伤，埃及分会收养了在此次事故中失去双亲的3名孤儿。）',
        },
        {
          name: 'TOGO',
          chineseName: '多哥',
          detail:
            '川海行联会（多哥）：成立于2006年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员423余名，个人会员867余名；累计援助失学适龄儿童900余名，援建健康饮水点84处；（多哥南部滨海区4省由于连续多日降雨，形成大面积洪涝灾害。据初步统计，当地受灾人口达5000余人，数千公顷农田被毁，一些重要的基础设施遭到严重损毁，多哥分会对灾区捐赠矿泉水10万余瓶，压缩饼干5万余包，以及救灾救援资金1000万法郎供当地受灾人民完成房屋和资金重组以及供应基本的生活保障）',
        },
        {
          name: 'LIBERIA',
          chineseName: '利比里亚',
          detail:
            '川海行联会（利比里亚）：成立于2012年8月，截止2022年12月底，该分会上交国际联会登记在录商业会员39名，个人会员2400名。援助失学适龄儿童1200余名，援建45处健康饮水点.（2020年5月5日利比里亚西北部大角山州发生两起山体滑坡，造成45人死亡。利比里业分会协调大型工程车辆16辆参与此次救灾道路恢复工作）。',
        },
        {
          name: 'SIERRA LEONE',
          chineseName: '塞拉利昂',
          detail:
            '川海行联会（塞拉利昂）：成立于2016年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员130余名，个人会员2200余名，援助失学适龄儿童1800余名；（2017年8月14日，塞拉利昂首都弗里敦因强降雨引发洪水和泥石流灾害，当地大量房屋被冲毁，死亡人数达312人，此次灾难造成当地超过2000人无家可归。塞拉利昂分会得知此消息后，派遣专业救援团队协助塞拉利昂红十字会开展救援工作。）',
        },
        {
          name: 'NAMIBIA',
          chineseName: '纳米比亚',
          detail:
            '川海行联会（纳米比亚）：成立于2012年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员489余名，个人会员2700余名，援助失学适龄儿童1200余名；援建健康饮水点51处；(2020年10月21日，纳米比亚全境发生蝗灾，一天可吃掉两千五百人的口粮，给当地带来很严重的粮食危机。纳米比亚分会号联合当地多家华人商会共同捐赠大米800吨，以此缓解当地粮食危机。）',
        },
        {
          name: 'BURUNDI',
          chineseName: '布隆迪',
          detail:
            '川海行联会（布隆迪 ）：成立于2015年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员105余名，个人会员1600余名，援助失学适龄儿童1700名，援建健康饮水点35处。(2016年4月11日，布隆迪首都布琼布拉以东18公里发生重大交通事故导致18人死亡，55人受伤，布隆迪分会会长李金文先生刚好经过事发地并亲自参与事故受伤人员的转运医院工作。）',
        },
        {
          name: 'RWANDA',
          chineseName: '卢旺达',
          detail:
            '川海行联会（卢旺达）：成立于2015年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员300余名，个人会员5000余名（卢旺达是农业大国，当地盛产茶叶、咖啡等经济农作物。卢旺达分会积极与当地政府沟通，协助当地有关部门将卢旺达当地多类经济农作物销往全球各类市场。2019年，卢旺达分会获选卢旺达节礼日最受欢迎外邦组织。）',
        },
        {
          name: 'KENYA',
          chineseName: '肯尼亚',
          detail:
            '川海行联会（肯尼亚）：成立于2014年5月，截止2022年12月底。该分会上交国际联会登记在录商业会员300余名，个人会员6000余名，援助失学适龄儿童1800名，援建健康饮水点79处；（在2020年的7月，肯尼亚迎来40年来最严重的干旱，致800万人受灾，严重影响了当地经济以及人民的正常生活，肯尼亚分会经过与当地有关部门的多次接洽后，免费为当地修建大型供水设施三处，解决了当地10万人的临时用水的问题。）',
        },
        {
          name: 'DJIBOUTI',
          chineseName: '吉布提',
          detail:
            '川海行联会（吉布提）：成立于2014年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员475余名，个人会员6700余名，吉布提分会在该国奥博克，塔朱拉等地区援建健康饮水点86处，为该地区居民的健康饮水问题和农业灌溉问题带来了一个质的飞跃(2019年2月3日，吉布提外海两艘渔船发生倾覆，得知此消息后，吉布提分会当即联系在其海域附近的中国华人商会的船只对其展开救援，及时的救援让这次意外的伤亡人数缩减到最小。）',
        },
        {
          name: 'COMOROS',
          chineseName: '科摩罗',
          detail:
            '川海行联会（科摩罗）：成立于2009年7月，2022年12月底。该分会上交国际联会登记在录商业会员227余名，个人会员3000余名，援助失学适龄儿童500名，援建健康饮水点19处。',
        },
        {
          name: 'NEPAL',
          chineseName: '尼泊尔',
          detail:
            '川海行联会（尼泊尔）：成立于2008年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员400余人，个人会员4800余名；（2015年4月25日，尼泊尔第二大城市博克拉发生8.1级大地震，上万人死亡，数万人受伤，被摧毁建筑及被困人员不计其数，尼泊尔分会第一时间组织分会义工以志愿者名义前往灾区进行人道援助，此次援助中尼泊尔分会累积为灾区捐赠饮用水100000瓶，帐篷5000顶及生活急需物资5吨。同年，尼泊尔分会被尼泊尔卫生组织评为“NASS单位”。）',
        },
        {
          name: 'VIET NAM',
          chineseName: '越南',
          detail:
            '川海行联会（越南）：成立于2017年6月，截止2022年12月底，该分会上交国际联会登记在录商业会员420余名，个人会员7800余名；援建健康饮水点128处，健康饮水援建计划自在越南实行以来，目前已为越南34处城镇提供清洁饮用水，受益人群达30000余人；（2020年10月28日，越南遭遇重大风暴自然灾害，众多楼房在此次灾害中倒塌，数万受灾灾民被紧急撤离，越南分会启动紧急救灾预案，配合国际联会从各国分会调拨近5吨灾区急需物资前往灾区实施人道救援。）',
        },
        {
          name: 'CAMBODIA',
          chineseName: '柬埔寨',
          detail:
            '川海行联会（柬埔寨）：成立于2017年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员420余名，个人会员11800余名；累计援助失学适龄儿童1800余名，援建健康饮水点36处；（2018年7月中旬，柬埔寨全国普降大雨引发当地多处地区出现洪涝灾害，13万余户民众受到洪涝灾害影响，柬埔寨分会组织发起多次以当地华人、华侨为主的募捐活动，国际联会拨款30万美金用以援助此次灾害受灾人群。）',
        },
        {
          name: `LAO PEOPLE'S DEMOCRATIC REPUBLIC`,
          chineseName: '老挝',
          detail:
            '川海行联会（老挝）：成立于2014年3月，截止2022年12月底。该分会上交国际联会登记在录商业会员780余名，个人会员9800余名，援助失学适龄儿童3800余名，援建健康饮水点32处；（2018年7月23日晚，老挝首都桑南内水电站副坝发生溃坝，洪水涌入阿速坡省萨南赛县13个村庄，其中6个村庄严重受损，约1.3万人受灾，老挝分会在得知此消息后，组织专业救援团队及分会义工前往事发地进行援助，并号召当地华人为灾民提供临时暂住场所。）',
        },
        {
          name: 'PHILIPPINES',
          chineseName: '菲律宾',
          detail:
            '川海行联会（菲律宾）：成立于2004年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员230余名，个人会员6600余名；援建健康饮水点82处，为援建健康饮水计划，菲律宾分会对当地近1400余处老化供水管道进行维修或更坏；（2006年2月，菲律宾东部莱特岛圣伯纳德镇的山体豁开一道巨大缺口，泥浆裹着岩石向下倾泻形成泥石流，造成2000余人遇难，菲律宾分会第一时间就近安排分会救灾人员的前往实施人道救援。灾后，为防止此类自然灾害的的再次发生，菲律宾分会的救灾义工与当地受灾灾民共同在灾区附近栽种了一定规模的防护林。）',
        },
        {
          name: 'INDONESIA',
          chineseName: '印度尼西亚',
          detail:
            '川海行联会（印度尼西亚）：成立于2004年，截止2022年12月底。该分会上交国际联会登记在录商业会员300余名，个人会员2800余名，援助适龄失学儿童1600名。，（印度尼西亚2015年全年共有261万公顷的林地和泥炭地发生意外火灾，火灾所产生的烟霾笼罩印尼大部分地区长达五个月之久，此类烟霾的吸入导致当地至少21人死亡，数以万计的当地居民因此患上呼吸道疾病。为缓解此类项非自然灾害对当地居民的健康损伤，印度尼西亚分会联系当地多家华侨诊所开启“华人0元义诊”活动，因此项活动受益人数突破千人。）',
        },
        {
          name: 'SRI LANKA',
          chineseName: '斯里兰卡',
          detail:
            '川海行联会（斯里兰卡）：成立于2014年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员120余名，个人会员4300余名，援助失学适龄儿童1400余名。（2016年5月17日，斯里兰卡暴雨引发大规模泥石流，14万人无家可归，受灾人数逾35万，斯里兰卡分会得知次消息后，立即启动紧急救灾预案，配合国际联会以及当地华侨为受灾人群提供临时住所，并从各国分会调拨近10吨灾区急需物资前往灾区实施人道主义援助。）',
        },
        {
          name: 'OMAN',
          chineseName: '阿曼',
          detail:
            '川海行联会（阿曼）：成立于2004年9月，截止2022年12月底。该分会上交国际联会登记在录商业会员180余名，个人会员3000余名；援建健康饮水点56处；（阿曼当地经济发达，但对于生活用品以及日常用品没有达到自供自足，为了当地居民以及分会会员达到真正的互惠互利，阿曼分会与多家当地华侨商会组成商业联盟，在阿曼马斯喀特，塞拉莱等地区成立了集体采购贸易处。）',
        },
        {
          name: 'IRAQ',
          chineseName: '伊拉克',
          detail:
            '川海行联会（伊拉克）：成立于2014年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员500余名，个人会员7000余名，援助失学适龄儿童2400余名，援建健康饮水点43处；（2017年11月12日晚，伊拉克北部地区苏莱曼尼亚省发生7级以上强烈地震，超过7200人受伤，约有1万2千栋建筑倒塌，伊拉克分会在灾后配合世卫组织和世界儿童基金会对当地居住环境进行深度调研，并为当地灾民捐赠24万美金用以灾后重建工作。）',
        },
        {
          name: 'TURKEY',
          chineseName: '土耳其',
          detail:
            '川海行联会（土耳其）：成立于2015年5月，截止2022年12月底。该分会上交国际联会登记在录商业会员500余名，个人会员9000余名；2016年土耳其分会协助土耳其华人设立矿产销售出口处，给该地区的矿产销量提升了约1亿吨；（2023年2月6日，土耳其东南部凌晨发生7.7级地震、土耳其分会得知消息后，连夜派出救灾人员前往事发地开始营救受灾被困人员，随即号召分会成员向当地捐赠捐大量救灾物资及救援资金。）',
        },
        {
          name: 'BULGARIA',
          chineseName: '保加利亚',
          detail:
            '川海行联会（保加利亚）：成立于2018年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员80余名，个人会员3200余名。（2021年12月23日，一辆旅游巴士在保加利亚一段高速公路上起火并发生爆炸，导致至少45人死亡，其中包括12名儿童，保加利亚分会得知此消息后，配合当地华侨医院为因此次事故而受伤的人群提供免费的医疗援助，并与当地多家华人商会对遇难者家属进行慰问。）',
        },
        {
          name: 'UZBEKISTAN',
          chineseName: '乌兹别克斯坦',
          detail:
            '川海行联会（乌兹别克斯坦）：成立于2004年10月，截止2022年12月底。该分会上交国际联会登记在录商业会员230余名，个人会员2800余名，援助失学适龄儿童2200名，2012年10月，援助健康饮水点43处；（2005年5月12日安集延骚乱事件，因其内部冲突，发生暴乱，严重影响到底当地居民生活。乌兹别克斯坦分会在此次暴乱活动中，号召所有分会会员在维护好个人自身安全的前提下对因此次暴乱活动中受伤的人群提供一定医药援助。并配合世卫组织建立多处“和平之家”用以保护受此次冲突影响的灾民。）',
        },
        {
          name: 'ARMENIA',
          chineseName: '亚美尼亚',
          detail:
            '川海行联会（亚美尼亚）: 成立于2015年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员300余名，个人会员2800余名。援建健康饮水点39处，援助失学适龄儿童2400余名。（亚美尼亚分会在当地商业会员的求助下，在亚美尼亚联合当地华人商会，对当地的矿石产业进行了产业深加工流程的升级，全面形成集采集、运输、销售为一体化的全方位产业链条。）',
        },
        {
          name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
          chineseName: '马其顿',
          detail:
            '川海行联会（马其顿）：成立于2013年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员110余名，个人会员约8000余名；累计援助适龄失学儿童1200余名、援建健康饮水点39处；（2016年8月， 斯科普里地区因多日强降雨引发泥石流灾害， 造成至少98人伤亡， 马其顿分会第一时间启动紧急救灾预案，就近协调26辆大型救灾设备前往灾区进行援助。）',
        },
        {
          name: 'SERBIA',
          chineseName: '塞尔维亚',
          detail:
            '川海行联会（塞尔维亚）：成立于2017年8月；截止2022年12月底。该分会上交国际联会登记在录商业会员210余名，个人会员约6000余名，援助失学适龄儿童800名；(2020年3月20号，塞尔维亚出现第一例新冠肺炎死亡病例，此后新冠感染数据持续飙升，塞尔维亚分会自当地疫情发生以来，在保障分会义工自身安全的前提下，安排6000余/人次会员参与志愿援助活动，并对当地多家医疗单位捐赠超10万个医用口罩用以支持当地医疗救治活动。同年，塞尔维亚分会因在当地新冠疫情的防控救治活动中的突出表现，被当地公益单位评选为“Blove”单位。）',
        },
        {
          name: 'GREECE',
          chineseName: '希腊',
          detail:
            '川海行联会（希腊 ）：成立于2006年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员159余名，个人会员约9000余名，援助当地适龄孤儿760余名。（2008年，北京奥运会火炬在希腊雅典举行取火交接仪式，北京奥运火炬在希腊境内传递的7天内，希腊分会组织境内600余名会员全程跟随，助力奥运火炬完美交接。）',
        },
        {
          name: 'ITALY',
          chineseName: '意大利',
          detail:
            '川海行联会（意大利）：成立于2015年11月，截止2022年12月底。该分会上交国际联会登记在录商业会员218余名，个人会员7000余名。（2020年3月15日，位于意大利北部的伦巴第大区疫情爆发，感染人员迅速增长，意大利分会对当地华人提供免费核酸检测及药品发放。为此受益人数突破万人。）',
        },
        {
          name: 'AUSTRIA',
          chineseName: '奥地利',
          detail:
            '川海行联会（奥地利）：成立于2015年9月，截止2022年12月底。该分会上交总会登记在录商业会员159余名，个人会员约8000余名；援助当地适龄孤儿600余名。（2021年7月9日早晨，一辆行驶在萨尔茨堡州地区的火车发生严重脱轨，奥地利分会得知此事后，立即启动紧急救援方案，协调分会登记多位职业技能会员前往始发地配合当地有关部门展开援救。）',
        },
        {
          name: 'CZECH REPUBLIC',
          chineseName: '捷克共和国',
          detail:
            '川海行联会（捷克）：成立于2017年11月，截止2022年12月底。该分会上交国际联会登记在录商业会员150余名，个人会员8000余名。（2021年8月5日下午，捷克布拉格西南方向发生火车相撞事故，捷克分会得知此事件后，就近协调当地多家华人商会旗下救护车辆参与此次伤员转运工作，为在此次意外事故中的受伤者争取到了更多治疗时间。）',
        },
        {
          name: 'GERMANY',
          chineseName: '德国',
          detail:
            '川海行联会（德国）：成立于2015年9月，截止2022年12月底。该分会上交国际联会登记在录商业会员218余名，个人会员15000余名；（2016年 4月，德国分会协助巴伐利亚地区华人商会发起“反工会无端罢工”游行，以此来帮助德国华人商会发出正确的政治需求。）',
        },
        {
          name: 'SWITZERLAND',
          chineseName: '瑞士',
          detail:
            '川海行联会（瑞士）：成立于2014年9月，截止2021年12月底。该分会上交国际联会登记在录商业会员156余名，个人会员约9600余名；援助当地适龄孤儿570余名。  （2015年2月20日，瑞士北部苏黎世州，两辆火车发生相撞事故，造成5人死亡，其中一人伤势严重。瑞士分会为此次事故中的97名轻伤者联系了多家华人医院为其提供免费医疗救治服务。）',
        },
        {
          name: 'FRANCE',
          chineseName: '法国',
          detail:
            '川海行联会（法国）：成立于2005年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员217余名，个人会员9000余名；（2018年10月14日晚，法国南部发生强降雨，导致大部分地区发生洪涝灾害；法国分会当即组织分会义工为此次救灾活动物资的转运工作提供免费车辆运送服务，后续统计，法国分会为此次援助提供的车辆转运工作累积达1300余/车次。）',
        },
        {
          name: 'UNITED KINGDOM',
          chineseName: '英国',
          detail:
            '川海行联会（英国）：成立于2016年3月，截止2022年12月底。该分会上交国际联会登记在录商业会员120余名，个人会员11000余名；（英国分会自成立之初就始终坚持与当地政府机关合作，共同对当地流浪人群提供必要医疗及生活援助。截止2022年12月底，英国分会累计为9460名流浪汉提供必要医疗及生活援助。并在英国各地建立30余处流浪汉收容机构，为此类流浪人群成立专项爱心救助基金。）',
        },
        {
          name: 'SPAIN',
          chineseName: '西班牙',
          detail:
            '川海行联会（西班牙）：成立于2015年6月，截止2022年12月底。该分会上交国际联会登记在录商业会员198余名，个人会员6000余名；(2021年12月西班牙北部出现连续强降雨天气，引发了多地出现洪涝灾害，导致当地居民流离失所。西班牙会分发布号召文件，号召分会会员及当地华侨为受灾人群提供临时住所，并与当地卫生部门共同组建医疗巡视义工组，为受灾人群提供免费医疗援助。）',
        },
        {
          name: 'PORTUGAL',
          chineseName: '葡萄牙',
          detail:
            '川海行联会（葡萄牙）：成立于2017年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员142余名，个人会员6000余名；累计援助失学适龄儿童1500余名，援建健康饮水点59处；（2021年10月，葡萄牙发生严重的大流行病，超过上百万人感染，葡萄牙分会得知次消息后，配合国际联会转运对应救治流感药品697箱，并组织分会登记职业技能义工配合当地的医疗机构共同展开相关的救助活动。）',
        },
        {
          name: 'DENMARK',
          chineseName: '丹麦',
          detail:
            '川海行联会（丹麦）：成立于2016年9月，截止到2022年12月底。该分会上交国际联会登记在录商业会员143余名，个人会员12000余名；（该国哥本哈根地区的垃圾污染存在许久，已严重影响到当地的动植物的生存空间；2018年6月，丹麦分会组织分会义工配合当地有关卫生部门展开清理垃圾活动，此次活动共计回收了近70000公斤造成环境污染的垃圾。为保护当地生态贡献了属于联会的力量。）',
        },
        {
          name: 'SWEDEN',
          chineseName: '瑞典',
          detail:
            '川海行联会（瑞典）：成立于2015年9月，截止2022年12月底。该分会上交国际联会登记在录商业会员290余名，个人会员10000余名（2018年7月26日，位于瑞典中部的萨尔纳、于斯达地区爆发森林火灾，瑞典分会第一时间组织分会义工前往火灾现场，为当地救灾单位提供后勤服务。）',
        },
        {
          name: 'FINLAND',
          chineseName: '芬兰',
          detail:
            '川海行联会（芬兰）：成立于2014年8月，截止到2022年12月底。该分会上交国际联会登记在录商业会员160余名，个人会员约8000余名；(2016年，荷兰分会在赫尔辛基地区配合当地多家华人商会收购当地多家破产新能源企业，让该地区400余失业人员重新回到岗位。)',
        },
        {
          name: 'UKRAINE',
          chineseName: '乌克兰',
          detail:
            '川海行联会（乌克兰）：成立于 2016 年 4月，截止2022年12月底。该分会上交国际联会登记在录商业会员160余名，个人会员9000余名；援助失学适龄儿童950余名，援建健康饮水点16处。（2018年 6月，乌克兰分会协助敖德萨地区当地华人商会成立对外出口贸易处，给该地区整体经济创收做出了巨大贡献。）',
        },
        {
          name: 'ISRAEL',
          chineseName: '以色列',
          detail:
            '川海行联会（以色列）：成立于2008年6月，截止到2022年12月底。该分会上国际联会登记在录商业会员118名，个人会员约6200余名；援建健康饮水点51处。（2010年12月，以色列北部海法地区发生森林大火，威胁到以色列第三大城市海法， 以色列分会紧急出资购买当地多家灭火器商户的灭火器材，免费捐赠给当地消防人员参与此次救灾活动。）',
        },
        {
          name: 'MEXICO',
          chineseName: '墨西哥',
          detail:
            '川海行联会(墨西哥)：成立于2011年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员200余名 ，个人会员6500余名.援建健康饮水点56处（飓风“曼纽尔”在2013年9月13日至9月20日期间沿着墨西哥太平洋沿海多次登陆，因此带来的暴雨使至少24个州遭遇到了特大洪水，至少169人在此次灾害中死亡或者失踪，墨西哥分会知悉消息第一时间组建相应的救援义工队前往灾区进行人道主义救援，在此期间多次配合国际联会运送多类救灾物资前往灾区展开援助）',
        },
        {
          name: 'CUBA',
          chineseName: '古巴',
          detail:
            '川海行联会（古巴）：成立于2018年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员50余名，个人会员1500余名；援助适龄失学儿童1500余名，援建健康饮水点387处（古巴的制糖业、旅游和镍出口是重要经济支柱，2019年受Cubaniquel 邀请参与峰会，此次峰会也让古巴分会和本土企业建立了良好的关系，更是在建立了良好的商业同盟关系， 为分会在当地的进出口贸易合作同盟打下了良好的基础。）',
        },
        {
          name: 'GUATEMALA',
          chineseName: '危地马拉',
          detail:
            '川海行联会（危地马拉）：成立于2017年11月，截止2022年12月底。该分会上交国际联会登记在录商业会员100余名 ，个人会员3000余名，援建健康饮水点76处，（2022年10月11日为应对飓风“朱丽叶”造成的灾害，危地马拉分会号召当地华人企业、商会为当地居民提供安全避难场所。灾后，危地马拉分会组织多次“清扫回收”活动，为当地的生态环境保护发挥自身价值。）',
        },
        {
          name: 'EL SALVADOR',
          chineseName: '萨尔瓦多',
          detail:
            '川海行联会（萨尔瓦多）：成立于2010年1月，截止2022年12月底。该分会上交国际联会登记在录商业会员80余名 ，个人会员3000余名，援助失学适龄儿童2100余名，援建健康饮水点20个；(2020年10月29号萨尔瓦多发生泥石流灾害，事故造成至少6人死亡，超过30人失踪，超过50栋建筑被损毁。事后萨尔瓦多分会组织发动联会义工及当地华人协助相关部门参与此次搜救活动，号召当地华人为失去房屋的受灾人群提供临时庇护场所。）',
        },
        {
          name: 'HONDURAS',
          chineseName: '洪都拉斯',
          detail:
            '川海行联会（洪都拉斯）：成立于2016年2月，截止2022年12月底。该分会上交国际联会登记在录商业会员300余名 ，个人会员5000余名，援助失学适龄儿童3400余名（2020年11月21日，飓风“约塔” 肆虐洪都拉斯，造成数10人死亡，57万人受灾，洪都拉斯北部多座城市遭洪水淹没，洪都拉斯分会号召全体会员参与此次救灾活动，该次救灾活动中，洪都拉斯分会共计出动975辆/车次、6437人/次参与此次救灾活动。）',
        },
        {
          name: 'NICARAGUA',
          chineseName: '尼加拉瓜',
          detail:
            '川海行联会（尼加拉瓜）：成立于2015年8月，截止2022年12月底。该分会上交国际联会登记在录商业会员100余名，个人会员1400余名。提供垃圾环保箱5000个，援助适龄失学儿童700余名。（2017年10月热带风暴引发强降雨，导致河流水位暴涨，灾害波及31个城市，导致受灾人口过万，尼加拉瓜分会得到消息立即筹备救援物资前往灾区救援。这也是尼加拉瓜分会成立以来，尼加拉瓜受灾最严重的一次）',
        },
        {
          name: 'PANAMA',
          chineseName: '巴拿马',
          detail:
            '川海行联会（巴拿马）：成立于2013年9月，截止2022年12月底。该分会上交国际联会登记在录商业会员200余名 ，个人会员7500余名。援助失学适龄儿童2000余名，援建健康饮水点139处。（2020年11月8日恩加-布格勒等地区的洪灾和山体滑坡造成了8人死亡，68人失踪，2000多人受伤，以及上万人生活受到不同程度的影响，巴拿马分会就近调配34台大型救灾车辆前往灾区配合当地政府和救灾团队共同进行救灾通道的打通工作。）',
        },
        {
          name: 'COLOMBIA',
          chineseName: '哥伦比亚',
          detail:
            '川海行联会（哥伦比亚）：成立于2007年8月，截止2022年10月底，该分会上交国际联会登记在录商业会员100余名，个人会员6200余名。援建健康饮水点80处，援助失学适龄儿童700余名，（2021年3月24日，哥伦比亚的一个小镇发生泥石流，35671人死亡，超过100多万人无家可归，哥伦比亚分会知悉消息后，启动紧急救灾预案配合国际联会协调大批抗灾所需物资转运灾区。该次救灾行动中，哥伦比亚分会共计出动762辆/车次、5437人/次参与此次救灾行动。）',
        },
        {
          name: 'BAHAMAS',
          chineseName: '巴哈马',
          detail:
            '川海行联会（巴哈马）：成立于2015年11月，截止2022年12月底。该分会上交国际联会登记在录商业会员100余名，个人会员2000余名；援助适龄失学儿童1800余名。援建健康饮水点95处（2019年9月7日飓风“多利安”重创巴哈马，导致巴哈马遭受重大损失，死亡人数上升至43人，成百上千人下落不明。巴哈马分会得到消息立即组织专业人员前往灾区展开救援，搭建临时安全避难所多处安置群众脱险，并提供价值50多万美元应急食品，医疗等物资，并协助当地政府为受灾人群完成救灾工作）',
        },
        {
          name: 'HAITI',
          chineseName: '海地',
          detail:
            '川海行联会（海地）：成立于2015年2月，截止2022年12月底。该分会上交国际联会登记在录商业会员100余名 ，个人会员2500余名，累计援助失学适龄儿童3000余名，援建健康饮水点37处（2016年10月初，超强飓风“马修”给当地自然生态及百姓带来毁灭性的影响，海地分会经过三个月的实地调研后，配合国际联会开展针对海地多所学校的重建维修工作，当地两万多名学生得以重回校园。）',
        },
        {
          name: 'JAMAICA',
          chineseName: '牙买加',
          detail:
            '川海行联会（牙买加）：成立于2012年10月，截止2022年12月底。该分会上交国际联会登记在录商业会员150余名 ，个人会员4000余名。援助失学适龄儿童2000余名。（在2015年3月金斯敦郊外的垃圾山意外失火多天，垃圾的燃烧散发大量有毒气体，当地数百人因此就医，牙买加分会组织当地多家华侨诊所配合当地政府为此类人群开展免费医疗救治活动。灾后，联合当地多家环境保护组织发起多次环境保护宣传活动，以此来呼吁当地居民共同保护生存环境。）',
        },
        {
          name: 'DOMINICA',
          chineseName: '多米尼克',
          detail:
            '川海行联会（多米尼克）：成立于2016年7月，截止2022年12月底。该分会上交国际联会登记在录商业会员120余名，个人会员2500余名，援助失学适龄儿童1560余名。援建健康饮水点43处（2017年，多米尼克遭遇自然灾害（玛丽亚）飓风，飓风经过处90%的房屋的房顶被损坏，多米尼克分会专门针对此次自然灾害成立专项基金，基金盈利全部用于受灾灾民住房的维修工作。）',
        },
        {
          name: 'PERU',
          chineseName: '秘鲁',
          detail:
            '川海行联会（秘鲁）：成立于2000年6月，截止2022年12月底，该分会上交国际联会登记在录商业会员240余名，个人会员7500余名。（自1849年首批“契约华工”运抵秘鲁，同时提出用华工代替黑奴。此后25年间约有10万名“契约华工”来到秘鲁开矿、筑路……。秘鲁闻名于世的中央铁路及许多重要的公路、矿山、港口，都凝聚着华工的生命和血汗。秘鲁分会成立的初衷就是帮助当地华裔多元化发展。现在老一辈秘鲁华商，大多经营餐馆及中小工商企业。年轻一代的华裔则从事医生、教授、工程师、艺术家和军、政官员等职业。）',
        },
        {
          name: 'ECUADOR',
          chineseName: '厄瓜多尔',
          detail:
            '厄瓜多尔（分会）：成立于2011年6月，截止2022年12月底，该分会上交国际联会登记在录商业会员50余名，个人会员4300余名。（2010年8月厄瓜多尔首都基多南部地区里氏发生6.9级地震，厄瓜多尔分会组织分会职业技能性义工以志愿者名义前往灾区参与救助搜寻工作，此次援助，厄瓜多尔分会主席张科先生，先后在灾区亲自参与搜寻，并成功解救3名灾后幸存人员。）',
        },
        {
          name: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
          chineseName: '委内瑞拉',
          detail:
            '川海行联会（委内瑞拉）：成立于2008年10月，截止2022年12月底，该分会上交国际联会登记在录商业会员20余名，个人会员3200余名，援助失学适龄儿童1700余名。援建健康饮水点68处。（2017年委内瑞拉出现严重通货膨胀，通货膨胀率超过2735%，贫困人口和极度贫困人口已经超过该国总人口80%；当地居民日常生活必需品需求已无法得到正常满足。国际联会响应世卫组织号召，紧急调拨近10吨生活必需品对该国居民进行人道援助。）',
        },
        {
          name: 'BRAZIL',
          chineseName: '巴西',
          detail:
            '川海行联会（巴西）：成立于2011年6月，截至2022年12月底，该分会上交国际联会登记在录商业会员260余名，个人会员11500余名；援助失学适龄儿童1600余名，援建健康饮水点49处。（2018年11月，里约热内卢一贫困山区发生山体滑坡，造成多人受伤以及死亡，巴西分会为援助当地有关部门的搜救工作，协调大型无人机20余架参与此次搜救活动。）',
        },
        {
          name: 'PARAGUAY',
          chineseName: '巴拉圭',
          detail:
            '川海行联会（巴拉圭）：成立于2019年11月，截止2022年12月底，该分会上交国际联会登记在录商业会员50余名 ，个人会员4000余名。（2022年1月12日巴拉圭首都亚松森发生火灾，造成2死17伤，巴拉圭分会协调当地华侨商会三架直升机参与救灾及伤员转送工作。同年巴拉圭分会与多家当地华侨商会组成商业联盟 ，借助国际联会在全球影响力，结合当地自身产品优势大力开展对外贸易出口。）',
        },
        {
          name: 'ARGENTINA',
          chineseName: '阿根廷',
          detail:
            '川海行联会（阿根延）：成立于2011年7月3日，截止到2022年12月底，该分会上交国际联会登记在录商业会员70余名，个人会员8000余名。（2013年8月6日阿根廷第三大城市罗萨里奥的居民区因大规模煤气泄漏而引发一起煤气爆炸事故。事故造成附近的一幢建筑物倒塌，其它多幢沦为危楼，有22人丧生，60人受伤。阿根廷分会得知后立即组织当地分会义工参与救灾搜救行动。）',
        },
        {
          name: 'CHILE',
          chineseName: '智利',
          detail:
            '川海行联会（智利）：成立于2010年6月，截止2022年12月底，该分会上交国际联会登记在录商业会员100余名，个人会员5600余名，援建健康饮水点76处。（2014年，位于智利中部的绝大部分地区由于长时间的未降雨天气，导致当地土壤长期干旱，严重影响当地农民的正常生产与生活。智利分会邀请国际联会水利专家对该地区进行实地调研，为当地相关部门提供科学合理应对方案。同年，智利分会对该地区援建三处中大型水利工程，用以缓解当地土地干旱带来的影响。）',
        },
        {
          name: 'URUGUAY',
          chineseName: '乌拉圭',
          detail:
            '川海行联会（乌拉圭）: 成立于2015年6月，截止2021年12月底, 该分会上交国际联会登记在录商业会员60余名，个人会员5000余名；援助适龄失学儿童1100余名，援建健康饮水点56处.（2016年4月乌拉圭遭遇暴风雨与洪水袭击。导致7人遇难, 无数房屋建筑物被摧毁。近4000余人流离失所。乌拉圭分会知悉消息后，紧急组织义工前往灾区救援。协助当地救援组织展开救援，并配合国际联会转运各分会捐赠的多类救灾物资。）',
        },
        {
          name: 'BOLIVIA, PLURINATIONAL STATE OF',
          chineseName: '玻利维亚',
          detail:
            '川海行联会（玻利维亚）：成立于2012年6月，截止2022年12月底，该分会上交国际联会登记在录商业会员40余名，个人会员3700余名。（2013年2月，位于玻利维亚南部暴雨成灾，引发的洪水造成了山体滑坡，泥石流等灾害波及超过8万家庭。玻利维亚分会紧急从救灾仓库中紧急调配价值80万元人民币的帐篷、急救药箱、应急食品等救援物资前往灾区，协助当地相关部门开展救援活动。）',
        },
      ],
    }
  },
  components:{
    TopEarth
  },
  mounted() {
    this.init()

  },
  methods: {
    
    //   let ds = [
    //     {
    //       name: '中国',
    //       point: [114.11, 22.4, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '中国香港',
    //     },
    //     {
    //       name: '印度',
    //       point: [78.96288, 20.593684, 0],
    //       itemStyleColor: '#0000FF',

    //       labelText: '印度',
    //     },
    //     {
    //       name: '意大利',
    //       point: [12.56738, 41.87194, 0],
    //       itemStyleColor: '#0000FF',
    //       details: 'XXXXXXXXXXXXXXXXXXXXXX',
    //       labelText: '意大利',
    //     },
    //     {
    //       name: '美国',
    //       point: [-95.712891, 37.09024, 0],
    //       itemStyleColor: '#0000FF',

    //       labelText: '美国',
    //     },
    //     // ==========================================
    //     {
    //       name: '蒙古',
    //       point: [103.846656, 46.862496, 0],
    //       itemStyleColor: '#0000FF',

    //       labelText: '蒙古',
    //     },
    //     {
    //       name: '加拿大',
    //       point: [-106.346771, 56.130366, 0],
    //       itemStyleColor: '#0000FF',

    //       labelText: '加拿大',
    //     },
       
    //     {
    //       name: '日本',
    //       point: [138.252924, 36.204824, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '日本',
    //     },

    //     {
    //       name: '越南',
    //       point: [105.550000,21.050000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '越南',
    //     },{
    //       name: '菲律宾',
    //       point: [121.030000, 14.400000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '菲律宾',
    //     },{
    //       name: '泰国',
    //       point: [100.350000, 13.450000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '泰国',
    //     },{
    //       name: '不丹',
    //       point: [89.450000, 27.310000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '不丹',
    //     },{
    //       name: '尼泊尔',
    //       point: [85.200000, 27.450000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '尼泊尔',
    //     },{
    //       name: '土耳其',
    //       point: [32.540000, 39.570000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '土耳其',
    //     },{
    //       name: '柬埔寨',
    //       point: [104.550000, 11.330000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '柬埔寨',
    //     },{
    //       name: '伊拉克',
    //       point: [44.300000, 33.200000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '伊拉克',
    //     },{
    //       name: '科威特',
    //       point: [48.000000, 29.300000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '科威特',
    //     },{
    //       name: '缅甸',
    //       point: [96.200000, 16.450000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '缅甸',
    //     },{
    //       name: '斯里兰卡',
    //       point: [80.705613, 7.665700, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '斯里兰卡',
    //     },{
    //       name: '孟加拉',
    //       point: [90.254063, 24.204019, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '孟加拉',
    //     },{
    //       name: '乌兹别克斯坦',
    //       point: [69.100000,41.200000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '乌兹别克斯坦',
    //     },{
    //       name: '印度尼西亚',
    //       point: [106.490000, -6.090000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '印度尼西亚',
    //     },{
    //       name: '亚美尼亚',
    //       point: [44.310000, 40.100000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '亚美尼亚',
    //     },{
    //       name: '文莱',
    //       point: [115.000000,4.520000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '文莱',
    //     },{
    //       name: '阿曼',
    //       point: [58.360000, 	23.370000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '阿曼',
    //     },{
    //       name: '新加坡',
    //       point: [103.864154,1.362735, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '新加坡',
    //     },{
    //       name: '马来西亚',
    //       point: [101.410000, 3.090000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '马来西亚',
    //     },{
    //       name: '老挝',
    //       point: [102.360000, 17.580000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '老挝',
    //     },{
    //       name: '委内瑞拉',
    //       point: [-66.550000, 10.300000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '委内瑞拉',
    //     },{
    //       name: '巴拉圭',
    //       point: [28.120000, 	-25.440000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '巴拉圭',
    //     },{
    //       name: '阿根廷',
    //       point: [-60.000000, -36.300000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '阿根廷',
    //     },{
    //       name: '智利',
    //       point: [-70.400000,	-33.240000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '智利',
    //     },{
    //       name: '厄瓜多尔',
    //       point: [-78.350000, -0.150000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '厄瓜多尔',
    //     },{
    //       name: '玻利维亚',
    //       point: [-68.100000,-16.200000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '玻利维亚',
    //     },{
    //       name: '秘鲁',
    //       point: [-77.000000,-12.000000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '秘鲁',
    //     },{
    //       name: '巴西',
    //       point: [-47.550000,-15.470000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '巴西',
    //     },{
    //       name: '哥伦比亚',
    //       point: [-74.000000,	4.340000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '哥伦比亚',
    //     },{
    //       name: '乌拉圭',
    //       point: [-56.110000,	-34.500000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '乌拉圭',
    //     },{
    //       name: '墨西哥',
    //       point: [	-99.100000,	19.200000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '墨西哥',
    //     },{
    //       name: '巴拿马',
    //       point: [-79.250000,9.000000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '巴拿马',
    //     },{
    //       name: '古巴',
    //       point: [	-82.220000,23.080000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '古巴',
    //     },{
    //       name: '尼加拉瓜',
    //       point: [-86.200000,12.060000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '尼加拉瓜',
    //     },{
    //       name: '巴哈马',
    //       point: [	-77.200000,25.050000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '巴哈马',
    //     },{
    //       name: '多米尼克',
    //       point: [1111,2222, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '多米尼克',
    //     },{
    //       name: '牙买加',
    //       point: [	-76.500000,	18.000000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '牙买加',
    //     },{
    //       name: '危地马拉',
    //       point: [	-90.220000,14.400000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '危地马拉',
    //     },{
    //       name: '萨尔瓦多',
    //       point: [	-89.100000,	13.400000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '萨尔瓦多',
    //     },{
    //       name: '洪都拉斯',
    //       point: [	-87.140000,14.050000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '洪都拉斯',
    //     },{
    //       name: '海地',
    //       point: [-72.200000,18.400000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '海地',
    //     },{
    //       name: '索马里',
    //       point: [45.250000,2.020000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '索马里',
    //     },{
    //       name: '苏丹',
    //       point: [32.350000,15.310000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '苏丹',
    //     },{
    //       name: '中非共和国',
    //       point: [18.350000,4.230000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '中非共和国',
    //     },{
    //       name: '布隆迪',
    //       point: [29.180000,-3.160000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '布隆迪',
    //     },{
    //       name: '埃及',
    //       point: [31.140000,30.010000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '埃及',
    //     },{
    //       name: '肯尼亚',
    //       point: [36.480000,-1.170000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '肯尼亚',
    //     },{
    //       name: '摩洛哥',
    //       point: [-6.240875,31.967759, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '摩洛哥',
    //     },{
    //       name: '卢旺达',
    //       point: [30.040000,-1.590000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '卢旺达',
    //     },
    //     {
    //       name: '喀麦隆',
    //       point: [11.350000,3.500000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '喀麦隆',
    //     },{
    //       name: '埃塞俄比亚',
    //       point: [38.420000,9.020000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '埃塞俄比亚',
    //     },{
    //       name: '利比亚',
    //       point: [17.583546,27.055803, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '利比亚',
    //     },{
    //       name: '利比里亚',
    //       point: [	-10.470000,6.180000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '利比里亚',
    //     },{
    //       name: '纳米比亚',
    //       point: [17.040000,	-22.350000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '纳米比亚',
    //     },{
    //       name: '科摩罗',
    //       point: [43.160000,-11.400000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '科摩罗',
    //     },{
    //       name: '吉布提',
    //       point: [42.200000,11.080000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '吉布提',
    //     },{
    //       name: '多哥',
    //       point: [1.200000,6.090000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '多哥',
    //     },{
    //       name: '津巴布韦',
    //       point: [31.020000,	-17.430000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '津巴布韦',
    //     },{
    //       name: '阿尔及利亚',
    //       point: [3.080000,36.420000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '阿尔及利亚',
    //     },{
    //       name: '塞拉利昂',
    //       point: [	-13.170000,8.300000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '塞拉利昂',
    //     },{
    //       name: '新西兰',
    //       point: [174.460000,	-41.190000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '新西兰',
    //     },{
    //       name: '澳大利亚',
    //       point: [149.080000,	-35.150000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '澳大利亚',
    //     },{
    //       name: '英国',
    //       point: [	-0.050000,51.360000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '英国',
    //     },{
    //       name: '法国',
    //       point: [2.200000,48.500000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '法国',
    //     },{
    //       name: '瑞典',
    //       point: [18.030000,59.200000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '瑞典',
    //     },{
    //       name: '芬兰',
    //       point: [25.030000,60.150000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '芬兰',
    //     },{
    //       name: '意大利',
    //       point: [12.290000,41.540000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '意大利',
    //     },{
    //       name: '俄罗斯',
    //       point: [37.350000,55.450000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '俄罗斯',
    //     },{
    //       name: '马其顿',
    //       point: [21.260000,42.010000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '马其顿',
    //     },{
    //       name: '西班牙',
    //       point: [	-3.450000,40.250000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '西班牙',
    //     },{
    //       name: '捷克',
    //       point: [14.220000,50.050000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '捷克',
    //     },{
    //       name: '以色列',
    //       point: [35.120000,31.470000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '以色列',
    //     },{
    //       name: '奥地利',
    //       point: [16.220000,48.120000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '奥地利',
    //     },{
    //       name: '塞尔维亚',
    //       point: [19.251107,42.890781, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '塞尔维亚',
    //     },{
    //       name: '瑞士',
    //       point: [7.280000,46.570000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '瑞士',
    //     },{
    //       name: '希腊',
    //       point: [23.460000,37.580000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '希腊',
    //     },{
    //       name: '德国',
    //       point: [13.250000,52.300000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '德国',
    //     },{
    //       name: '乌克兰',
    //       point: [30.280000,50.300000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '乌克兰',
    //     },{
    //       name: '葡萄牙',
    //       point: [	-9.100000,38.420000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '葡萄牙',
    //     },{
    //       name: '丹麦',
    //       point: [12.340000,55.410000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '丹麦',
    //     },{
    //       name: '保加利亚',
    //       point: [23.200000,42.450000, 0],
    //       itemStyleColor: '#0000FF',
    //       labelText: '保加利亚',
    //     },
    //   ]

    //   // 点配置信息
    //   let series = ds.map((item) => {
    //     return {
    //       //  type: 'lines3D',
    //       // coordinateSystem: 'globe',
    //       // blendMode: 'lighter',
    //       effect: {
    //         // 飞线 特效
    //         show: true,
    //         peroid: 2,
    //         trailColor: 'greenyellow',
    //       },
    //       lineStyle: {
    //         // 飞线颜色
    //         width: 2,
    //         color: '#02fd00',
    //         opacity: 0.8,
    //       },
    //       name: item.name, // 是否显示左上角图例
    //       type: 'scatter3D',
    //       coordinateSystem: 'globe',
    //       blendMode: 'lighter',
    //       symbolSize: 16, // 点位大小
    //       itemStyle: {
    //         color: item.itemStyleColor, // 各个点位的颜色设置
    //         // opacity: 1, // 透明度
    //         // borderWidth: 1, // 边框宽度
    //         // borderColor: 'rgba(255,255,255,0.8)' //rgba(180, 31, 107, 0.8)
    //       },
    //       label: {
    //         show: true, // 是否显示字体
    //         position: 'left', // 字体位置。top、left、right、bottom
    //         formatter: item.labelText, // 具体显示的值
    //         textStyle: {
    //           color: '#fc0303', // 字体颜色
    //           borderWidth: 0, // 字体边框宽度
    //           borderColor: '#fff', // 字体边框颜色
    //           fontFamily: 'sans-serif', // 字体格式
    //           fontSize: 12, // 字体大小
    //           fontWeight: 600,
    //         },
    //       },
    //       data: [item.point], // 数据来源
    //     }
    //   })
    //   const option = {
    //     backgroundColor: '#000',

    //     globe: {
    //       baseTexture: require('./assets/earth.jpg'),
    //       // heightTexture: require('./assets/bathymetry_bw_composite_4k.jpg'),
    //       displacementScale: 0.1,
    //       shading: 'lambert',
    //       environment: require('./assets/white.png'),
    //       light: {
    //         ambient: {
    //           intensity: 0.1,
    //         },
    //         main: {
    //           intensity: 1.5,
    //         },
    //       },

    //       layers: [
    //         {
    //           type: 'blend',
    //           blendTo: 'emission',
    //           texture: require('./assets/night.jpg'),
    //         },
    //         {
    //           type: 'overlay',
    //           texture: require('./assets/clouds.png'),
    //           shading: 'lambert',
    //           distance: 5,
    //         },
    //       ],
    //     },
    //     series: series,
    //   }
    //   const myChart = echarts.init(document.getElementById('mychart')) // 图标初始化
    //   myChart.setOption(option) // 渲染页面
    //   //随着屏幕大小调节图表
    //   window.addEventListener('resize', () => {
    //     myChart.resize()
    //   })
    //   // myChart.on('click',function (params) {
    //   //   var op = myChart.getOption();
    //   //   console.log(op,1111111111)
    //   //   console.log(params)
    //   // })
    // },
    init() {
      var GIO = require('giojs')
      var container = document.getElementById('globalArea')
      var controller = new GIO.Controller(container)
      var configs = {
        control: {
          stats: false,
          disableUnmentioned: false,
          lightenMentioned: true,
          inOnly: false,
          outOnly: false,
          initCountry: 'MY',
          halo: true,
        },
        color: {
          surface: 1744048,
          selected: this.deepColor == true ?'2141154':'#FF0932',
          in: 'red',
          out: 2141154,
          halo: 2141154,
          background: 0,
        },
        brightness: {
          ocean: 0.5,
          mentioned: 0.5,
          related: 0.5,
        },
      }
      controller.configure(configs)
      controller.addData([
        {
          e: 'MY',
          i: 'US',
          v: 10000,
          inColor: '#0000FF',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'RU',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'MN',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'KR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'KM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'IL',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'DJ',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'JP',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'VN',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'PH',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'TH',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'IN',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BT',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'NP',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'TR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'KH',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'IQ',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'KW',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'MM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'LK',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BD',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'UZ',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'ID',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'AM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BN',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'OM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'SG',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'MY',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'LA',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'VE',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'PY',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'AR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },

        {
          e: 'MY',
          i: 'CL',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'EC',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BO',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'PE',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CO',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'UY',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CA',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
       
        {
          e: 'MY',
          i: 'MX',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'PA',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CU',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'NI',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BS',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'DM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'JM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'GT',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'SV',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'HN',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'HT',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'SO',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'SD',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CF',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BI',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'EG',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'KE',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'MA',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'RW',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CM',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'ET',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'LY',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'LR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'NA',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'TG',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'ZW',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'DZ',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'SL',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },

        {
          e: 'MY',
          i: 'NZ',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'AU',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'GB',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'FR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'SE',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'FI',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'IT',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
      
        {
          e: 'MY',
          i: 'MK',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'ES',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CZ',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'AT',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'RS',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CH',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'GR',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'DE',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'UA',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'PT',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'DK',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'BG',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
        {
          e: 'MY',
          i: 'CN',
          v: 10000,
          inColor: '#00baee',
          outColor: '#00baee',
        },
      ])
      // 启用自动旋转功能，将转速设置为1（同时1也是默认的转速）
      // controller.setAutoRotation(true, 0.5);
      controller.disableUnmentioned(true)
      controller.onCountryPicked(this.callback)
      controller.setTransparentBackground( true );
      controller.configure({
        brightness: {
          related:1,
        },
      })
      
      controller.init()
    },
    callback(selectedCountry) {
      this.showDetail = true
      for (var i = 0; i < this.selectedCountryData.length; i++) {
        if (
          this.selectedCountryData[i].name.toUpperCase() == selectedCountry.name
        ) {
          var dataList = this.selectedCountryData[i]
        }
      }
      this.detailLst = dataList
    },
    
  },
}
</script>
<style>
  .logoImg{
    width: 50%;
    display: block;
    margin: 0 auto;
    padding: 15px 0;
  }
  .globlWarper{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-image: url('./assets/topLogo.jpg');
    width:100%;
    height: 110px;
    background-size:100% 100%;
  }
  .borderWarper{
    background-image: url('./assets/bottomLogo.png') !important;

  }
  .inner{
    height: 110px;
    background: rgba(0,0,0,.4);
}
</style>
